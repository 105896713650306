@use '@styles/base';

.root {
  position: relative;

  &__video {
    width: 100%;
    max-width: 1560px;
    height: 100%;
    max-height: 600px;
    border-radius: 18px;
  }
}
