@use '../../../../styles/base';

.root {
  width: 100%;
  padding: 10px;

  display: grid;
  gap: 10px;

  background-color: base.$grey-dark;
}
