@use '@styles/base';

.root {
  width: 100%;

  &__title {
    font-size: 14px;
    font-weight: 700;
  }

  &__inputBar {
    width: 100%;
    height: 35px;
    margin: 10px 0;
    padding: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid base.$grey-extra-light;
    border-radius: 5px;

    &_input {
      border: none;
      background: none;

      font-size: 12px;
      font-weight: 700;
    }

    &_submit {
      border: none;
      background: none;

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__message {
    font-size: 12px;
    font-weight: 700;
    color: base.$red;
  }
}
