@use '@styles/base';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;

  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 1000;

  &__progress {
    flex: 3;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    border: none;
    background: none;
  }
}
