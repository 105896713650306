@use '@styles/base';

.root {
  position: relative;

  &__name {
    font-size: 16px;
    font-weight: 700;
  }

  &:hover {
    .root__wrapper {
      display: block;
    }
  }

  &__wrapper {
    display: none;

    width: 120px;
    border: 1px solid base.$grey-extra-light;
    border-radius: 5px;
    padding: 10px;

    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1500;

    list-style: none;
    background-color: base.$white;

    &_item {
      font-size: 12px;
      font-weight: 700;
      text-decoration-line: none;
      color: black;
      transition: .2s ease-in-out;

      &:hover {
        opacity: .8;
      }
    }
  }
}
