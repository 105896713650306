@use '@styles/base';

.root {
  width: 100%;
  max-width: 100px;
  height: 35px;
  padding: 5px 10px;

  border: 1px solid rgba(140, 80, 216, 0.15);
  border-radius: 8px;

  font-size: 13px;
  font-weight: 600;
  color: base.$grey-brown;
}
