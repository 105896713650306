@use '@styles/base.scss';

.root {
  &__btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid base.$grey-extra;
    background: none;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
  }

  &__counter {
    position: absolute;
    bottom: -5px;
    left: 50%;

    width: 25px;
    height: 17px;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 9px;
    font-weight: 800;
    color: base.$white;
    background-color: base.$red;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__heading {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_title {
      font-size: 24px;
      font-weight: 800;
    }
  }

  &__close {
    border: none;
    background: none;
  }

  &__content {
    width: 100%;
    max-width: 610px;
    border-radius: 16px;
    border: 1px solid base.$grey-extra-light;
    padding: 20px 10px 20px 20px;

    position: absolute;
    z-index: 1500;
    top: 90px;
    right: 60px;
    background-color: base.$white;
    @media screen and (max-width: base.$breakpoint-lg) {
      top: 0px;
      left: 0px;
    }
  }

  &__wrapper {
    width: 100%;
    padding-right: 10px;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: base.$grey-ultra;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: base.$grey-ultra;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      border: 2px solid base.$grey-ultra;
      background-color: base.$grey-normal-dark;
    }
  }
}
