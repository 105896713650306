@use '@styles/base.scss';

.root {

  &__content {
    width: 100%;
    max-width: 600px;
    margin: 30px 0;

    display: grid;
    gap: 20px;

    @media (max-width: base.$breakpoint-lg) {
      margin-top: 20px;
    }

    a {
      position: relative;
      width: fit-content;

      color: base.$white;
      text-decoration: none;

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;

        content: '';
        width: 0;
        height: 2px;
        transition: .3s ease-in-out;
        background-color: base.$white;
      }
    }
  }

  &__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;

    &_item {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  &__text {
    font-size: 15px;
    color: base.$white;
    margin: 0;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
