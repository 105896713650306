@use '@styles/base.scss';

.root {
  margin-top: 120px;
  border-radius: 10px;
  border: 0.5px solid base.$grey-extra-light;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: base.$breakpoint-md) {
      display: none;
    }
  }

  &__slider {
    display: none;
    width: 100%;
    overflow: hidden;

    @media (max-width: base.$breakpoint-md) {
      display: block;
    }
  }

  &__item {
    padding: 30px;

    @media (max-width: base.$breakpoint-lg) {
      padding: 30px 15px;
    }

    @media (max-width: base.$breakpoint-md) {
      padding: 15px;
    }

    &:first-of-type {
      border-right: 1px solid base.$grey-extra-light;

      @media (max-width: base.$breakpoint-md) {
        border-right: none;
      }
    }

    &:last-of-type {
      border-left: 1px solid base.$grey-extra-light;

      @media (max-width: base.$breakpoint-md) {
        border-left: none;
      }
    }
  }

  &__title {
    margin-top: 30px;
    font-size: 32px;
    font-weight: 400;

    @media (max-width: base.$breakpoint-lg-big) {
      font-size: 26px;
    }

    @media (max-width: base.$breakpoint-lg) {
      font-size: 24px;
    }
  }

  &__description {
    margin-top: 20px;

    font-size: 16px;
    color: base.$grey;

    @media (max-width: base.$breakpoint-lg-big) {
      font-size: 14px;
    }

    @media (max-width: base.$breakpoint-lg) {
      font-size: 12px;
    }
  }

  &__image {
    width: 78px;
    height: 78px;
  }
}
