@use '@styles/base.scss';

.root {
  position: relative;

  &__avatar {
    cursor: pointer;
  }

  &__wrapper {
    width: 150px;
    border: 1px solid base.$grey-extra-light;
    border-radius: 10px;
    padding: 20px;

    position: absolute;
    left: -200%;
    z-index: 2500;

    background-color: base.$white;
    transition: all .2s ease-in-out;
    cursor: default;

    &_list {
      &__wrapper {
        padding: 0;
        margin: 0;
      }

      &__item {
        margin-bottom: 30px;
        list-style-type: none;

        a {
          font-size: 13px;
          color: base.$grey;
          text-decoration-line: none;
          transition: all .2s ease-in-out;

          &:hover {
            opacity: 0.7;
            transform: scale(1.2);
          }
        }
      }
    }
  }

  &__btn {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 14px;
    color: base.$grey;
    font-weight: 600;

    background: none;
    border: none;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

}
