@use '@styles/base';

.root {

  &__wrapper {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
    color: base.$grey-main;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 35px;
    padding: 12px 18px;
    background: transparent;
    width: 100%;
    border: 1px solid rgba(207, 219, 213, 0.6);
  }
}
