@use '@styles/base';

.root {
  display: flex;
  align-items: center;
  gap: 10px;

  &__title {
    width: 100%;
    max-width: 100px;
    height: 35px;
    border-radius: 8px;

    display: grid;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;

    color: base.$purple;
    background-color: rgba(140, 80, 216, 0.15);
  }
}
