@use '@styles/base.scss';

.root {
  position: relative;
  height: 350px;
  border-radius: 5px;
  overflow: hidden;

  transform: translateX(0.5px);

  &:hover {
    .root__image {
      transform: scale(1.2);
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    height: 350px;
    margin: auto;
    object-fit: cover;
    object-position: center;
    transition: transform .2s ease-in-out;

    &_hide {
      display: none;
    }
  }

  &__wrapper {
    width: 100%;
    height: 350px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;

    &_art {
      position: absolute;
      left: 20px;
      bottom: 20px;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
      color: base.$white;
    }

    &_btn {
      background: none;
      border: none;

      svg {
        width: 100px;
        height: 100px;
      }
    }
  }
}

