@use '@styles/base';

.root {
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;

  &__mobile {
    display: none;
    flex-direction: row;
    justify-content: space-between;

    background: url("../../../../assets/images/auth-back-img.png") no-repeat center/cover;

    @media screen and (max-width: base.$breakpoint-md) {
      display: flex;
    }
  }

  .mainLogo {
    max-width: 200px;
  }

  .info {
    max-width: 420px;
    @media screen and (max-width: base.$breakpoint-lg) {
      max-width: unset;
    }

    &__title {
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
      color: base.$coffee-dark;
      @media screen and (max-width: base.$breakpoint-lg) {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
    }

    &__description {
      margin-top: 30px;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 25px;
      color: base.$grey;
      @media screen and (max-width: base.$breakpoint-lg) {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
}
