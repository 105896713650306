@use '@styles/base';

.root {
  width: 100%;
  max-width: 328px;
  height: 205px;

  border-radius: 8px;
  position: relative;

  @media (max-height: base.$breakpoint-md) {
    max-width: unset;
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &__edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border: 1px solid #C2C2C2;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    bottom: 15px;

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__upload {
    border-radius: 10px;
    padding: 15px;
    background-color: base.$white;
  }

  &__drop {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    border: none;
    background: none;
    transition: transform .2s ease-in-out;
  }

  &__meta {
    text-align: center;

    &_title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &_desc {
      margin-top: 10px;
      font-size: 14px;
      color: base.$grey;
    }
  }

}
