@use '@styles/base';

.root {
  background: #F5F7F7;
  border-radius: 16px;
  padding: 20px;

  @media (max-width: base.$breakpoint-md) {
    padding: 15px;
  }

  &__main {
    margin-top: 20px;
  }

  &__desc {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(207, 219, 213, 0.6);
  }
}
