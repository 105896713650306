@use '@styles/base';

.root {
  &__wrapper {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    margin-top: 55px;

    @media (max-width: base.$breakpoint-lg-big) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: base.$breakpoint-lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: base.$breakpoint-md) {
      grid-template-columns: repeat(1, 1fr);
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
    }
  }

  &__more {
    display: block;
    border: none;
    border-radius: 10px;
    margin: 50px auto 0;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: underline;
    color: base.$black;
    background: none;
    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
