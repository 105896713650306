@use '@styles/base';

.root {
  &__btn {
    font-size: 14px;
    font-weight: 700;
    color: base.$grey-main;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 35px;
    padding: 12px 18px;
    margin-top: 20px;
    background: transparent;
    width: 100%;
    border: 1px solid rgba(207, 219, 213, 0.6);

    img {
      width: 10px;
      height: 6px;
      transform: rotate(-90deg);
      transition: transform .2s ease-in-out;
    }

    &_active {
      img {
        transform: rotate(0deg);
      }
    }
  }

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    color: base.$grey-brown;
  }

  &__info {
    list-style: none;
    margin-top: 15px;

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: base.$grey-brown;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_bold {
      li {
        font-weight: 700;
      }
    }
  }
}
