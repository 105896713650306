@use '@styles/base';

.input {
  width: 120px;
  height: 30px;
  border: none;

  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: base.$grey-normal-extra;

  background-color: base.$grey;
}

