@use '@styles/base';

.root {
  display: block;

  @media (max-width: base.$breakpoint-md) {
    display: none;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    color: base.$grey-main;
    margin-top: 8px;
    margin-left: 20px;
  }

  &_list {
    margin-top: 12px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    &__link {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: base.$grey-main;
      padding: 17px 20px;
      border-radius: 28px;
      margin-bottom: 8px;
      text-decoration: none;
      transition: 0.4s ease-in-out;

      &:hover {
        background: rgba(235, 240, 240, 0.5);
        color: base.$black;
      }
    }

    &__item {
      &_disabled {
        opacity: 0.3;
        cursor: not-allowed;

        .root_list__link {
          pointer-events: none;
        }
      }

      &_active {
        .root_list__link {
          background: rgba(235, 240, 240, 0.5);
          color: base.$black;
        }
      }
    }

    &__icon {
      width: 22px;
      height: 22px;
      margin-right: 14px;
    }
  }
}
