@use '@styles/base';

.root {
  width: -moz-fit-content;
  width: fit-content;

  display: grid;
  justify-content: flex-start;
  align-items: center;

  padding: 8px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 24px;
  color: base.$grey;
  background-color: rgba(80, 74, 72, 0.15);
}
