@use '@styles/base';

.root {

}

.track_root {
  display: flex;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 5px;
}

.track {
  width: 100%;
  height: 6px;
  border: 2px solid base.$grey-light;
  align-self: center;
}

.thumb_root {
  height: 2px;
  width: 2px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb {
  width: 0;
  height: 0;
  border-radius: 10px;
  background-color: #fff;

  border: none;
  outline: none;
}
