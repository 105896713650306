@use '@styles/base';

.root {
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  overflow: auto;

  @media (max-width: base.$breakpoint-md) {
    padding-bottom: 10px;
  }

  &__divide {
    width: 100%;
    height: 1.5px;
    background-color: base.$violet;

    @media (max-width: base.$breakpoint-sm-big) {
      display: none;
    }

    &_disabled {
      opacity: .4;
    }
  }
}
