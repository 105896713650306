@use '../../../../styles/base';
@use '../../../../styles/main';

.root {
  &_block {
    margin: 10px 0;

    &_inner {
      &_processing {
        opacity: 0.3;
      }
    }
  }

  &_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: base.$grey-main;
    width: 100%;
    border: 1px solid rgba(207, 219, 213, 0.6);
    border-radius: 23px;
    background: none;
    padding: 15px;
    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &_loader {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -15px;

    &_text {
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
      margin: 13px 0 0;
    }
  }
}

.loader {
  display: inline-block;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left: 3px solid base.$black;
  border-top: 3px solid base.$black;
  border-bottom: 3px solid base.$black;
  animation: rotate 1s linear infinite;
  position: relative;
  z-index: 8;
}

.loader:before {
  content: "";
  position: absolute;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border: 3px solid base.$black;
  opacity: 0.3;
  top: -3px;
  left: -3px;
  box-sizing: border-box;
  z-index: -1;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
