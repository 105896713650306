@use '@styles/base';

.root {
  border-radius: 10px;
  padding: 15px 10px;
  background-color: rgba(235, 240, 240, 0.5);

  &__wrapper {
    margin-top: 30px;
    min-height: 700px;
  }

  &__actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 30px;

    &_save {
      margin-top: 30px;
      width: 90px;
      height: 35px;
      border: none;
      border-radius: 5px;

      font-weight: 700;
      color: base.$white;
      background-color: base.$violet;
      transition: .2s ease-in-out;

      &:hover {
        color: base.$white;
        background-color: base.$black;
      }
    }
  }
}
