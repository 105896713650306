@use '@styles/base';

.root {
  position: relative;
  max-width: 1140px;
  border-radius: 10px;
  border: 1px solid base.$grey-extra-light;

  display: flex;
  align-items: stretch;
  cursor: pointer;

  &__info {
    flex: 1.2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_main {
      flex: 1.2;
      padding: 24px 20px;
    }

    &_meta {
      border-top: 1px solid base.$grey-extra-light;
      padding: 24px 20px;

      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    &_title {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &_label {
      font-size: 12px;
    }

    &_text {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 700;
    }

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      list-style: none;
    }
  }

  &__image {
    flex: 1;
    position: relative;

    &_md {

    }

    &_sm {
      max-width: 300px;
    }

    &_preview {
      width: 100%;
      height: 100%;

      position: absolute;
      inset: 0;

      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      object-position: center;
      object-fit: cover;
    }
  }

  &__actions {
    position: absolute;
    right: 10px;
    bottom: 10px;

    display: flex;
    justify-content: flex-end;
    gap: 10px;

    &_btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid base.$grey-extra-light;
      background-color: base.$white;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s ease-in-out;

      img {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: base.$violet;
      }
    }
  }
}
