@use '@styles/base';

.root {
  display: flex;
  align-items: center;
  margin-top: 20px;

  &__save {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: base.$white;
    background: base.$violet;
    border: none;
    border-radius: 4px;
    max-width: 120px;
    width: 100%;
    height: 46px;
    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  &__cancel {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: base.$violet;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #7A52F4;
    border-radius: 4px;
    border: none;
    max-width: 165px;
    width: 100%;
    height: 46px;
    margin-left: 20px;
    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
