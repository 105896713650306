@use '@styles/base';

.root {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &__disabled {
    opacity: .4;
  }

  &__badge {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: base.$violet-light;

    @media (max-width: base.$breakpoint-md) {
      width: 20px;
      height: 20px;
    }
  }

  &__label {
    font-size: 13px;
    font-weight: 500;
    color: base.$grey-light;

    @media (max-width: base.$breakpoint-md) {
      font-size: 10px;
    }
  }
}
