@use "./base.scss";
@import "./global/popup";
@import "./global/checkbox";
@import "./global/btn";

.main {
  width: 100%;
  margin: 0 auto;
}

.container {
  padding: 0 45px;
  z-index: 1000;
  position: relative;

  @media (max-width: base.$breakpoint-md) {
    padding: 0 25px;
  }
}

.hide__it {
  display: none;
}
