@use '@styles/base';

.root {
  width: 100%;
  max-width: 300px;

  &__header {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 8px;
    margin: 5px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: base.$purple;

    background-color: rgba(140, 80, 216, 0.15);
    transition: opacity .2s ease-in-out;

    img {
      width: 7px;
      transform: rotateZ(-90deg);
      transition: transform .2s ease-in-out;
    }

    &_active {
      img {
        transform: rotateZ(0deg);
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 10px;

    margin-top: 10px;
    text-align: left;

    &_title {
      font-size: 12px;
      font-weight: 600;
      color: base.$grey-light;
    }

    ul {
      padding: 0;
      margin: 5px 0 0;

      list-style: none;
    }
  }

  &__fee {
    font-size: 13px;
    color: base.$grey-brown;
    margin: 10px 0;
  }

  &__amount {
    font-size: 13px;
    color: base.$grey-brown;
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 3px;
  }

  &__percentage {
    .root__amount {
      font-weight: 600;
    }
  }

  &__infinity {
    padding-top: 5px;
    font-size: 18px;
    line-height: 15px;
  }
}
