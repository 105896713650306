@use '@styles/base';

.root {
  max-width: 1140px;

  border: 1px solid base.$grey-extra-light;
  border-radius: 10px;
  cursor: pointer;

  &__top {
    display: flex;
    align-items: stretch;

    &_info {
      flex: 1.2;
      padding: 24px 20px;

      border-bottom: 1px solid base.$grey-extra-light;
    }

    &_image {
      flex: 1;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-top-right-radius: 10px;

        position: absolute;
        inset: 0;

        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__bottom {
    padding: 24px 20px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 24px 20px;

    &_btn {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 1px solid base.$grey-extra-light;
      background-color: base.$white;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s ease-in-out;

      img {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background-color: base.$violet;
      }
    }
  }

  &__group {
    max-width: 250px;
    margin: 13px 0;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  &__label {
    font-size: 12px;
  }

  &__text {
    margin-top: 7px;
    font-size: 18px;
    font-weight: 700;

    &_uppercase {
      margin-top: 7px;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &__artists {
    margin: 13px 0;

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      list-style: none;
    }
  }
}
