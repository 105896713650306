@use '@styles/base.scss';

.root {
  width: 100%;
  margin: 10px 0;
  padding: 10px 0;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;

  border-radius: 5px;
  background-color: base.$white;

  @media screen and (max-width: base.$breakpoint-xxl) {
    padding: 5px 0;
    gap: 5px;
  }

  &__btn {
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    transition: all .2s ease-in-out;

    @media screen and (max-width: base.$breakpoint-xl) {
      padding: 0 5px;
    }

    &_active {
      background-color: base.$white;
    }

    &:hover {
      background-color: base.$white;
    }
  }
}
