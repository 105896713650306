@use '@styles/base.scss';

.link {
  text-decoration-line: none;
}

.root {
  max-width: 368px;
  width: 100%;
  border: 1px solid base.$grey-extra;
  border-radius: 20px;
  overflow: hidden;
  background-color: base.$white;
  padding: 5px;

  @media (max-width: base.$breakpoint-md) {
    margin: 0 auto 25px;
  }

  &:last-child {
    @media (max-width: base.$breakpoint-md) {
      margin-bottom: 0;
    }
  }

  &__info {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    @media (max-width: base.$breakpoint-xxl) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__name {
      font-size: 22px;
      font-weight: 700;
      color: base.$black;
      display: flex;
      align-items: center;
      margin-right: 5px;
      text-transform: capitalize;
      width: 70%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__eth {
      padding-top: 5px;
      font-size: 14px;
      font-weight: 700;
      color: base.$grey-main;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  &__cover {
    max-width: 368px;
    width: 100%;
    height: 300px;
    border-radius: 4px 4px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &__avatar {
    width: 63px;
    height: 63px;
    object-fit: cover;
    border: 5px solid base.$white;
    border-radius: 50%;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -32px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__wrapper {
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__art {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 800;
    color: base.$black;
  }

  &__btn {
    width: 46px;
    height: 46px;
    border: 1px solid rgba(207, 219, 213, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: base.$white;
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: base.$grey-main;
    margin-top: 20px;
  }

  &_artworks {
    display: flex;
    border-top: 1px solid rgba(207, 219, 213, 0.6);
    margin-top: 20px;
    padding-top: 20px;
    justify-content: space-around;
    width: 100%;

    &__item {
      text-align: center;
      width: 100%;
      border-right: 1px solid rgba(207, 219, 213, 0.6);

      &:last-child {
        margin-right: 0;
        border-right: none;
      }

      &_badge {
        text-align: left;
        border-right: none;
        width: 100%;
      }

    }

    &__number {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      color: base.$black;
    }

    &__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: base.$grey-main;
      text-transform: capitalize;
    }
  }

  &__GalleryBadge {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
