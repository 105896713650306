@use '@styles/base.scss';

.root {
  border-radius: 4px;
  border: 1px solid rgba(207, 219, 213, 0.6);

  @media screen and (max-width: base.$breakpoint-sm) {
    margin-bottom: 25px;
  }

  &__link {
    text-decoration-line: none;
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: base.$grey-main;
    margin-top: 20px;
  }

  &_artworks {
    display: flex;
    border-top: 1px solid rgba(207, 219, 213, 0.6);
    margin-top: 20px;
    padding-top: 20px;

    &__item {
      text-align: center;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__number {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      color: base.$black;
    }

    &__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: base.$grey-main;
      text-transform: capitalize;
      margin-top: 7px;
    }
  }

  &_gallery {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &_member {
    padding-bottom: 25px;

    &__cover {
      height: 300px;
      @media (max-width: base.$breakpoint-md) {
        height: 230px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
      }
    }

    &__context {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -31px;
      padding-left: 15px;
      padding-right: 15px;

      &_btn {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 1px solid base.$grey-light;

        display: grid;
        align-items: center;
        justify-content: center;
        background-color: base.$white;
      }
    }

    &__avatar {
      width: 63px;
      height: 63px;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid base.$white;
    }

    &__info {
      margin-top: 20px;
      padding-left: 15px;
      padding-right: 15px;

      &__inner {
        border-bottom: 1px solid rgba(207, 219, 213, 0.6);
        padding-bottom: 20px;
      }
    }

    &__nickname {
      font-size: 16px;
      font-weight: 700;
      color: base.$black;
      transition: color .2s ease-in-out;

      &:hover {
        color: base.$grey;
      }
    }

    &__name {
      font-size: 20px;
      font-weight: 800;
      color: base.$black;
      margin-top: 17px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__email {
      font-weight: 700;
      font-size: 14px;
      color: base.$grey-main;
      margin-top: 20px;
      text-decoration-line: none;
      transition: color .2s ease-in-out;

      &:hover {
        color: base.$grey;
      }
    }
  }
}
