@use '@styles/base.scss';

.root {
  padding: 40px;
  background-color: base.$black;

  @media (max-width: base.$breakpoint-lg) {
    padding: 30px 25px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: base.$breakpoint-lg) {
      flex-direction: column-reverse;
    }
  }
}
.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: base.$breakpoint-lg) {
    flex-direction: column;
  }
}
.text {
  color: white;
  max-width: 500px;
}
.img1 {
  width: auto;
  height: 82px;
}
.img2 {
  width: auto;
  height: 82px;
}
