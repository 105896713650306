@use "../base.scss";

.popup {
  position: relative;
  z-index: 10000;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: 0.3s ease-in-out;

  &__overlay {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &__block {
    background-color: base.$coffee-light;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 5;
    max-width: 615px;
    width: 100%;
    padding: 25px 28px 50px;
    @media (max-width: base.$breakpoint-md) {
      top: unset;
      transform: unset;
      padding-top: 38px;
      padding-bottom: 40px;
    }
  }

  &__close {
    width: 40px;
    height: 40px;
    border: none;
    position: absolute;
    right: 28px;
    top: 25px;
    background: none;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    color: base.$coffee-dark;
    text-transform: uppercase;
    margin: 0;
    @media (max-width: base.$breakpoint-md) {
      font-size: 20px;
    }
  }

  &__date {
    margin-top: 60px;
    @media (max-width: base.$breakpoint-md) {
      margin-top: 35px;
    }

    &_title {
      font-size: 18px;
      font-weight: 800;
      color: base.$coffee-dark;
      text-transform: uppercase;
      margin: 0;
      @media (max-width: base.$breakpoint-md) {
        font-size: 15px;
      }
    }

    &_group {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      @media (max-width: base.$breakpoint-md) {
        flex-wrap: wrap;
        margin-top: 15px;
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: block;
    }
  }

  &__form {
    margin-top: 55px;

    &_group {
      max-width: 265px;
      width: 100%;
      @media (max-width: base.$breakpoint-md) {
        max-width: 400px;
        margin-bottom: 15px;
      }
    }

    &_label {
      display: block;
      font-size: 13px;
      font-weight: 800;
      color: base.$coffee-dark;
    }

    &_input {
      margin-top: 8px;
      background-color: base.$coffee-back;
      padding: 0 15px;
      height: 50px;
      border: none;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      width: 100%;
      font-size: 15px;
      font-weight: 400;
      color: base.$grey;
    }
  }

  &__submit {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    @media (max-width: base.$breakpoint-md) {
      display: block;
      margin-top: 0;
    }

    .popup__form_group {
      max-width: 400px;
    }

    .popup__form_input {
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: base.$grey;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: base.$grey;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: base.$grey;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: base.$grey;
      }
    }
  }

  &__btn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.08em;
    width: 135px;
    height: 50px;
    border: none;
    background-color: base.$coffee-main;
    color: base.$coffee-light;
    text-transform: uppercase;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
    @media (max-width: 768px) {
      margin-top: 5px;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      font-style: italic;
      background-color: base.$coffee-dark;
    }
  }

  &__wrap {
    margin-top: 110px;

    &_title {
      font-size: 18px;
      font-weight: 900;
      color: base.$coffee-dark;
      text-transform: uppercase;
      margin: 0;
    }

    &_text {
      font-size: 18px;
      font-weight: 600;
      color: base.$coffee-dark;
      margin-bottom: 0;
      margin-top: 65px;
    }

    &_btn {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.08em;
      width: 135px;
      height: 50px;
      border: none;
      background-color: base.$coffee-main;
      color: base.$coffee-light;
      text-transform: uppercase;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      margin-top: 75px;
      transition: 0.4s ease-in-out;

      &:hover {
        font-style: italic;
        background-color: base.$coffee-dark;
      }
    }
  }

  &__profile {
    background: rgba(81, 52, 43, 0.75);
    position: fixed;
    top: 0;
    z-index: 1505;
    width: 100%;
    height: 100%;
    overflow: auto;

    &_block {
      background-color: base.$coffee-light;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 5;
      max-width: 375px;
      width: 100%;
      padding: 20px 28px 30px;
    }

    &_close {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 26px;
      height: 26px;
      border: none;
      background: none;

      img {
        width: 26px;
        height: 26px;
      }
    }

    &_title {
      color: base.$coffee-dark;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
    }

    &_form {
      margin-top: 35px;

      .popup__form_label {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .popup__form_input {
        margin-top: 0;
        height: 40px;
        max-width: 320px;
      }

      .popup__btn {
        margin-top: 20px;
      }
    }

    &_group {
      margin-bottom: 20px;

      &_inner {
        position: relative;

        .popup__form_input {
          padding-right: 41px;
        }
      }
    }

    &_icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      cursor: pointer;

      &_disabled {
        opacity: 0.3;
      }
    }

    &_error {
      font-size: 13px;
      color: base.$red;
      margin: 0;
    }
  }

  &__panel {
    background: rgba(51, 44, 41, 0.85);
    position: fixed;
    top: 0;
    z-index: 1505;
    width: 100%;
    height: 100%;
    overflow: auto;

    &_block {
      background-color: base.$grey;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 5;
      max-width: 1600px;
      width: 100%;
      padding: 40px 20px 65px;
      border: 1px solid rgba(145, 145, 145, 0.6);
      border-radius: 5px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: base.$breakpoint-xxl) {
        top: unset;
        transform: unset;
      }
    }

    &_close {
      display: block;
      background: none;
      border: none;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 8;

      img {
        width: 26px;
        height: 26px;
      }
    }

    &_title {
      font-size: 24px;
      font-weight: 600;
      color: base.$coffee-back;
      text-transform: uppercase;
      margin: 0;
    }

    &_inner {
      display: flex;
    }

    &_info {
      max-width: 740px;
      width: 100%;

      &_wrap {
        margin-left: 22px;
      }

      &_email {
        font-size: 16px;
        font-weight: 700;
        color: base.$coffee-back;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        margin: 0;
      }

      &_name {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        color: base.$coffee-back;
        margin: 10px 0 0;
      }

      &_number {
        font-size: 14px;
        font-weight: 400;
        color: base.$coffee-back;
        margin: 10px 0 0;
      }

      &_left {
        display: flex;
        align-items: center;
      }

      &_right {
        display: flex;
        align-items: center;
      }

      &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(173, 164, 160, 0.2);
        border-radius: 5px;
        padding: 16px 97px 16px 20px;
        margin-top: 35px;
      }

      &_img {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        object-fit: cover;
      }

      &_pause {
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        color: base.$coffee-back;
        letter-spacing: 0.08em;
        margin: 0;
      }

      &_controller {
        display: block;
        margin-left: 13px;
        background: transparent;
        border: none;
        width: 18px;
        height: 18px;
        padding: 0;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &_group {
      display: flex;
      align-items: flex-start;
      max-width: 600px;
      width: 100%;

      > div {
        flex: 1 1 50%;

        &:nth-child(2) {
          margin-left: 130px;
        }
      }
    }

    &_wrap {
      margin-top: 40px;

      &_room {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        color: base.$coffee-back;
        margin: 0;
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 16px;
          margin-right: 20px;
        }
      }

      &_address {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        margin: 20px 0 0;
        color: base.$grey-normal-extra;
        max-width: 160px;
        width: 100%;
      }

      &_title {
        font-size: 18px;
        font-weight: 700;
        margin: 0;
        color: base.$coffee-back;
      }

      &_text {
        font-size: 15px;
        line-height: 19px;
        font-weight: 400;
        margin: 20px 0 0;
        color: base.$grey-normal-extra;
      }
    }

    &_control {
      max-width: 770px;
      width: 100%;
      margin-left: 50px;
      margin-top: 35px;

      &_inner {
        max-width: 300px;
        width: 100%;

        &:nth-child(1) {
          .popup__panel_control_type {
            span {
              color: base.$green;
            }
          }
        }

        &:nth-child(2) {
          margin-left: 40px;
        }
      }

      &_info {
        margin-top: 20px;
        display: flex;
      }

      img {
        width: 100%;
      }

      &_text {
        font-size: 18px;
        font-weight: 700;
        color: base.$coffee-back;
        margin: 0;
        max-width: 300px;
        width: 100%;

        &:nth-child(2) {
          margin-left: 40px;
        }

        span {
          font-size: 13px;
          font-weight: 400;
          color: base.$grey-normal-extra;
          margin-left: 10px;
        }
      }

      &_type {
        font-size: 14px;
        line-height: 20px;
        color: base.$grey-normal-extra;
        margin: 0;

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &__video {
    background: rgba(51, 44, 41, 0.85);
    position: fixed;
    top: 0;
    z-index: 1505;
    width: 100%;
    height: 100%;
    overflow: auto;

    &_block {
      background-color: base.$grey;
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      max-width: 905px;
      width: 100%;
      padding: 40px 70px;
      border: 1px solid rgba(145, 145, 145, 0.6);
      border-radius: 5px;
      @media (max-width: base.$breakpoint-xxl) {
        top: unset;
        transform: unset;
      }
    }

    &_close {
      display: block;
      background: none;
      border: none;
      width: 26px;
      height: 26px;
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 8;

      img {
        width: 26px;
        height: 26px;
      }
    }

    &_title {
      font-size: 24px;
      font-weight: 700;
      color: base.$coffee-back;
      text-transform: uppercase;
      margin: 0;
    }

    &_wrap {
      margin-top: 20px;

      img {
        width: 100%;
      }
    }
  }

}

.input__error_message {
  padding: 10px 0;
  color: base.$coffee-dark;
  font-size: 14px;
  font-weight: 600;
}
