@use '@styles/base';

.root {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 25px 20px;

  display: flex;
  justify-content: center;

  background: base.$white;

  @media screen and (max-width: base.$breakpoint-lg) {
    padding: 35px 25px;
    margin-top: -30px;
  }

  @media screen and (max-width: base.$breakpoint-md) {
    min-height: unset;
    order: 2;
    border-radius: 20px 20px 0 0;
  }

  .content_root {
    max-width: 570px;
    width: 100%;

    @media screen and (max-width: base.$breakpoint-xxl) {
      max-width: 470px;
    }
  }
}
