@use '@styles/base';

.root {
  &__image {
    width: 100%;
    height: 220px;
    border-radius: 8px;

    object-position: center;
    object-fit: cover;
  }

  &__info {
    padding: 24px 20px;

    &_title {
      font-size: 26px;
      font-weight: 700;
    }
  }

  &__artists {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    list-style: none;
  }
}
