@use '@styles/base';

.root {
  margin-left: 20px;
  max-width: 445px;
  width: 100%;

  @media (max-width: base.$breakpoint-md) {
    margin-left: 0;
    margin-top: 25px;
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #5D5D5B;
  }

  &__main {
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(207, 219, 213, 0.6);
  }

  &__list {
    display: flex;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px;

    &__item {
      margin-right: 20px;
      width: 45px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(207, 219, 213, 0.6);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__block {
    display: flex;
    @media (max-width: base.$breakpoint-md) {
      flex-direction: column;
    }
  }

  &__wrapper {
    max-width: 215px;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(207, 219, 213, 0.6);
    @media (max-width: base.$breakpoint-md) {
      max-width: unset;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-left: 20px;
      @media (max-width: base.$breakpoint-md) {
        margin-left: 0;
        margin-bottom: 0;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: base.$grey-main;
    }

    &__text {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: base.$black;
      margin-top: 5px;
      border: none;
      background: none;

      &::-webkit-input-placeholder {
        font-weight: 700;
        font-size: 14px;
        color: base.$grey-main;
      }

      &::-moz-placeholder {
        font-weight: 700;
        font-size: 14px;
        color: base.$grey-main;
      }

      &:-ms-input-placeholder {
        font-weight: 700;
        font-size: 14px;
        color: base.$grey-main;
      }

      &:-moz-placeholder {
        font-weight: 700;
        font-size: 14px;
        color: base.$grey-main;
      }
    }
  }
}
