@use '@styles/base';

.root {
  width: 100%;

  &__title {
    font-size: 42px;
    font-weight: 800;
    line-height: 51px;
    color: base.$black;
    margin-bottom: 30px;

    @media (max-width: base.$breakpoint-md) {
      display: none;
    }
  }
}
