@use '@styles/base';

.root {
  padding: 15px 30px;
  background-color: base.$grey-dark;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__group {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 15px;

    &_text {
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;

      color: base.$white
    }

    &_btn {
      width: 195px;
      height: 50px;
      border: none;
      border-radius: 10px;

      font-size: 14px;
      font-weight: 900;
      text-transform: uppercase;
      color: base.$white;
      background-color: base.$violet;
      transition: .2s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
