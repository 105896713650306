@use '@styles/base.scss';

.main {
  position: relative;

  //&:before {
  //  display: block;
  //  content: "";
  //  width: 100%;
  //  height: 100%;
  //  max-height: 2000px;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background: radial-gradient(circle at top left, #efcff4, white 40%);
  //  z-index: 0;
  //
  //  @media screen and (max-width: base.$breakpoint-lg) {
  //    height: 15%;
  //  }
  //}
}

.root {
  width: 100%;
  min-height: base.$body;
  position: relative;
}
