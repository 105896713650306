@use '@styles/base';

.root {
  width: 100%;
  border-radius: 16px;
  padding: 20px;
  background-color: #F5F7F7;

  @media (max-width: base.$breakpoint-md) {
    padding: 15px;
  }
}
