@use '@styles/base';

.root {
  text-align: center;

  &__number {
    font-size: 18px;
    font-weight: 800;
    color: base.$black;
  }

  &__text {
    font-size: 12px;
    font-weight: 500;
    color: base.$grey-main;
    margin-top: 8px;
  }
}
