@use '@styles/base';
@use "@styles/main";

.popup {
  &__small {
    max-width: 582px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    margin: 0 auto;
    background-color: base.$grey;
    border: 1px solid rgba(145, 145, 145, 0.6);
    padding: 40px 100px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    &_title {
      font-size: 24px;
      font-weight: 700;
      color: base.$coffee-back;
      text-transform: uppercase;
      margin: 0;
    }

    &_close {
      display: block;
      width: 26px;
      height: 26px;
      position: absolute;
      right: 20px;
      top: 20px;
      background: transparent;
      border: none;

      img {
        width: 26px;
        height: 26px;
      }
    }

    &_text {
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      color: base.$coffee-back;
      margin: 40px 0 0;
    }

    &_bottom {
      margin-top: 43px;
    }

    &_group {
      margin-top: 43px;

      .popup__small_btn {
        width: 217px;
      }

      .popup__small_btn_ghost {
        width: 100px;
      }
    }

    &_controller {
      margin-top: 43px;

      .popup__small_btn {
        width: 120px;
      }

      .popup__small_btn_ghost {
        width: 120px;
      }
    }

    &_btn {
      font-size: 14px;
      font-weight: 600;
      color: base.$white;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      width: 120px;
      height: 50px;
      background-color: base.$violet;
      border: none;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      transition: 0.4s ease-in-out;

      &:disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      &:hover {
        font-style: italic;
      }

      &_ghost {
        font-size: 14px;
        font-weight: 600;
        color: base.$violet;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        width: 240px;
        height: 50px;
        background-color: transparent;
        border: 2px solid base.$violet;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        margin-left: 20px;
        transition: 0.4s ease-in-out;

        &:disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        &:hover {
          font-style: italic;
          color: base.$white;
          background-color: base.$violet;
        }
      }
    }
  }

  &__dark {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    inset: 0;
    top: 0;
    z-index: 10;
  }
}
