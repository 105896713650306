@use "@styles/base.scss";

.root {

  &__title {
    font-size: 12px;
    color: base.$grey;
    margin-bottom: 15px;
  }
}
