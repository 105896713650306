.track_root {
  display: flex;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 5px;
}

.track {
  width: 100%;
  height: 8px;
  border: 2px solid #ccc;
  border-radius: 10px;
  align-self: center;
}

.thumb_root {
  height: 8px;
  width: 8px;
  border-radius: 10px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #fff;

  border: none;
  outline: none;
}
