@use '@styles/base';

.root {
  max-width: 770px;
  width: 100%;
  height: 64px;
  position: relative;

  &__input {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: base.$black;
    border: 1px solid #CFDBD5;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    background: base.$white;
    padding: 20px;

    &::-webkit-input-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
    }

    &::-moz-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
    }

    &:-ms-input-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
    }

    &:-moz-placeholder {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    border: none;

    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: base.$white;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
