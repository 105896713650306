@use '@styles/base.scss';

.root {
  margin-top: 24px;

  &__subtitle {
    font-size: 18px;
    font-weight: 800;
    color: base.$black;
    margin-top: 38px;
  }

  &_group {
    background: rgba(235, 240, 240, 0.5);
    border-radius: 16px;
    padding: 22px 26px 10px;
    margin-top: 16px;
    margin-bottom: 24px;

    &__item {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(207, 219, 213, 0.6);
      margin-bottom: 16px;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &_error {
        border-bottom: 1px solid base.$red;
      }

      &_message {
        position: relative;
        top: 10px;

        font-size: 12px;
        color: base.$red;
      }
    }

    &__info {
      margin-right: 15px;

      &__subtitle {
        font-size: 12px;
        font-weight: 500;
        color: base.$grey-main;
      }

      &__text {
        font-size: 14px;
        font-weight: 700;
        color: base.$black;
        margin-top: 4px;
      }
    }

    &__label {
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: base.$grey-main;
    }

    &__input {
      width: 100%;
      display: block;
      font-size: 14px;
      font-weight: 700;
      color: base.$black;
      border: none;
      background: none;
      margin-top: 10px;

      &::-webkit-input-placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: base.$grey-main;
      }

      &::-moz-placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: base.$grey-main;
      }

      &:-ms-input-placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: base.$grey-main;
      }

      &:-moz-placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: base.$grey-main;
      }
    }

    &__area {
      height: 150px;
      resize: none;
      padding-right: 7px;
      @media (max-width: base.$breakpoint-md) {
        height: 95px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: base.$grey-ultra;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        border: 1px solid base.$grey-ultra;
        background-color: base.$grey-normal-dark;
      }
    }
  }
}
