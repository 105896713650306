@use '@styles/base.scss';

.root {
  width: 100%;
  height: 600px;
  max-width: 1564px;
  margin: auto;
  border-radius: 16px;

  position: relative;

  @media (max-width: base.$breakpoint-md) {
    border-radius: 0;
  }

  &_slider {
    width: 100%;
    max-width: 1564px;
    overflow: hidden;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 600px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &_wrapper {
      position: relative;
      z-index: 1500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 850px;
      width: 100%;

      padding-bottom: 80px;
    }

    &_link {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.8px;
      width: 118px;
      height: 44px;
      background: base.$violet;
      color: base.$white;
      text-decoration-line: none;
      border: none;
      border-radius: 45px;
      transition: 0.4s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      text-align: center;

      &:hover {
        background: base.$purple-violet;
      }
    }

    &_title {
      font-size: 42px;
      font-weight: 800;
      margin-top: 25px;
      text-align: center;

      @media screen and (max-width: base.$breakpoint-md) {
        font-size: 33px;
      }

      @media (max-width: base.$breakpoint-sm-big) {
        font-size: 28px;
      }
    }

    &_description {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 25px;

      @media screen and (max-width: base.$breakpoint-md) {
        font-size: 14px;
        line-height: 17px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    &_image {
      width: 100%;
      height: 600px;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
      position: absolute;
      z-index: 0;
    }
  }
}
