@use '@styles/base.scss';

.root {
  display: flex;
  max-width: 645px;
  width: 100%;
  margin-left: 15px;

  @media (max-width: base.$breakpoint-lg) {
    flex-direction: column;
    margin-left: 0;
  }

  &__group {
    margin-top: 25px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: base.$breakpoint-lg) {
      margin-top: 20px;
    }

    &_item {
      display: flex;
      align-items: center;

      @media (max-width: base.$breakpoint-lg) {
        margin-bottom: 15px;
      }
    }
  }

  &__content {
    max-width: 510px;
    width: 100%;
    margin-left: 25px;

    @media (max-width: base.$breakpoint-lg) {
      margin-left: 0;
    }
  }

  &__icon {
    width: 29px;
    height: 29px;
  }

  &__title {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: base.$white;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: base.$breakpoint-lg) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 15px;
    font-weight: 500;
    color: base.$white;
    display: inline-block;
    margin-left: 18px;
  }

  &__address {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: base.$white;
    margin: 0;

    @media (max-width: base.$breakpoint-lg) {
      margin-top: 20px;
    }
  }

  &__phone {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: base.$white;
    margin-top: 30px;
    text-decoration: none;

    @media (max-width: base.$breakpoint-lg) {
      margin-top: 20px;
    }
  }
}
