@use '@styles/base.scss';

.root {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 20px;
  z-index: 1500;

  max-width: fit-content;
  margin-right: 30px;
  padding: 10px 30px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;

  //background-color: base.$black;

  @media (max-width: base.$breakpoint-md) {
    padding: 10px 15px;
  }

  @media (max-width: base.$breakpoint-sm-big) {
    left: 0;
    right: 0;
    margin: auto;
  }

  &__item {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 10px;

    -webkit-filter: invert(100%);
    filter: invert(100%);

    text-align: center;
  }

  &__number {
    font-size: 24px;
    font-weight: 700;

    @media (max-width: base.$breakpoint-sm) {
      font-size: 16px;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: base.$breakpoint-sm) {
      font-size: 12px;
    }
  }
}
