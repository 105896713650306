@use '@styles/base';

.root {

  &__admin, &__user, &__gallery, &__artist {
    width: 22px;
    height: 22px;
    margin-bottom: 0;
    border-radius: 50%;

    display: grid;
    align-items: center;
    justify-content: center;

    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
    color: base.$white;
    text-transform: uppercase;

    background-color: base.$blue-primary;
  }

  &__admin {
    background: base.$blue-ocean;
  }

  &__user {
    background: base.$blue;
  }

  &__gallery {
    background: base.$green;
  }

  &__artist {
    background: base.$purple;
  }
}
