@use '@styles/base';

.root {
  position: relative;

  button {
    width: 130px;
    height: 36px;
    border: 1px solid base.$grey-light-extra;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    background: none;
    transition: opacity 0.4s ease-in-out;
    @media screen and (max-width: base.$breakpoint-md) {
      margin-top: 15px;
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      margin-right: 8px;
    }
  }

  &__wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 55px;
    background-color: base.$white;
    z-index: 1500;
    border: 1px solid base.$grey-light-extra;
    border-radius: 12px;
    list-style: none;
    padding: 10px;

    &_item {
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
      margin: 10px 0;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
