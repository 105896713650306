@use '@styles/base';

.root {
  display: block;
  max-width: 1684px;
  border-radius: 10px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: all .2s ease-in-out;

  @media (max-width: base.$breakpoint-md) {
    display: none;
  }
}
