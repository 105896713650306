.sassy--select {
  border: none;
  border-radius: .25rem;
  display: inline;
  padding: .2rem .4rem
}

.sassy--select__container {
  width: 120px; /* unset */
  border-radius: .25rem;
  position: relative
}

/* CUSTOM STYLE */
.sassy--select__container ~ .sassy--select__container {
  width: 75px;
}

.sassy--select:focus {
  box-shadow: var(--ring-shadow);
  outline: none
}

.sassy--select.sassy--select__disabled {
  opacity: var(--disabled-opacity);
  pointer-events: none
}

.sassy--select.sassy--select__disabled:focus {
  box-shadow: none
}

.sassy--select__dropdown {
  background: var(--select-background);
  border: none;
  border: var(--outline);
  border-radius: .5rem;
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow: auto;
  padding: .5rem;
  position: absolute;
  scrollbar-width: none;
  top: 1.5rem;
  z-index: 2
}

.sassy--select__dropdown::-webkit-scrollbar {
  display: none
}

.sassy--option, .sassy--select {
  font-family: var(--font);
  font-size: 1rem;
  font-weight: 500
}

.sassy--option:focus {
  box-shadow: var(--ring-shadow);
  outline: none
}

.sassy--option__disabled {
  color: var(--disabled-color);
  pointer-events: none
}

.sassy--select:hover {
  cursor: context-menu
}

.sassy--option {
  background: var(--option-background);
  border: none;
  border-radius: .25rem;
  cursor: pointer;
  margin-top: .25rem;
  padding: .25rem .4rem;
  transition: all .1s ease
}

.sassy--option:first-of-type {
  margin-top: 0
}

.sassy--option:hover, .sassy--option__active {
  background-color: var(--theme-color);
  box-shadow: var(--shadow);
  color: var(--selected-date-color)
}

.sdp {
  background: var(--background);
  border: none;
  border: var(--outline);
  border-radius: .5rem;
  box-shadow: var(--shadow);
  padding: .75rem;
  width: 16rem
}

.sdp--disabled {
  pointer-events: none
}

.sdp--month-name:focus, .sdp--square-btn:focus, .sdp:focus {
  box-shadow: var(--ring-shadow);
  outline: none
}

.sdp--month-name {
  border: none;
  border-radius: .5rem;
  padding: .5rem;
}

.sdp--text {
  font-family: var(--font);
  font-weight: 500;
  margin: 0
}

.sdp--text__inactive {
  color: var(--disabled-color);
  pointer-events: none
}

.sdp--square-btn {
  align-items: center;
  background-color: var(--btn-background);
  border-radius: .5rem;
  cursor: pointer;
  display: flex;
  font-family: var(--font);
  height: 1.8rem;
  justify-content: center;
  transition: all .2s ease-in-out;
  width: 1.8rem
}

/* CUSTOM STYLE */
.sdp--grid .sdp--square-btn {
  width: 100%;
  height: 50px;
  border: 1px solid rgba(207, 219, 213, .6);
}

.sdp--square-btn__outlined, .sdp--square-btn__outlined:focus:hover {
  border: var(--outline)
}

.sdp--square-btn:focus:hover {
  box-shadow: none
}

.sdp--square-btn__shadowed, .sdp--square-btn__shadowed:focus:hover {
  box-shadow: var(--shadow)
}

.sdp--date-btn__selected {
  background-color: var(--theme-color);
  box-shadow: var(--shadow);
  color: var(--selected-date-color)
}

/* CUSTOM STYLE */
.sdp--grid .sdp--date-btn__selected {
  border: 1px solid #7A52F4;
}

.sdp--date-btn__selected.sdp--square-btn:focus {
  box-shadow: var(--shadow)
}

.sdp--header {
  max-width: 200px; /* unset */
  align-items: center;
  display: flex;
  justify-content: space-between
}

/* CUSTOM STYLE */
.sdp--header .sdp--square-btn {
  border: none;
  outline: none;
  box-shadow: none;
}

.sdp--grid {
  display: none;
  gap: 4px;
  grid-template-columns:repeat(7, 1fr);
  justify-items: center;
  margin: 1rem 0 0
}

/* CUSTOM STYLE */
.sdp--grid ~ .sdp--grid {
  display: grid;
}

.sdp--grid > p.sdp--text {
  padding: .5rem 0
}

.sdp--header__main {
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: center
}

.sdp--select__month button {
  text-align: left
}

.sdp, .stp {
  --font: inherit;
  --shadow: transparent 0px 0px 0px 0px, transparent 0px 0px 0px 0px, rgba(0, 0, 0, .1) 0px 1px 3px 0px, rgba(0, 0, 0, .06) 0px 1px 2px 0px;
  --shadow-md: transparent 0px 0px 0px 0px, transparent 0px 0px 0px 0px, rgba(0, 0, 0, .1) 0px 4px 6px -1px, rgba(0, 0, 0, .06) 0px 2px 4px -1px;
  --theme-color: rgba(122, 82, 244, .6); /* #60a5fa */
  --text-color: #1f1f1f;
  --background: #fff;
  --btn-background: #fff;
  --select-background: #fff;
  --option-background: #fff;
  --selected-date-color: #fff;
  --disabled-color: #bdbdbd;
  --disabled-opacity: 50%;
  --outline: 1px solid #cbd5e1;
  --ring-shadow: 0px 0px 0px 2px rgb(122, 82, 244);
  background: var(--background);
  color: var(--text-color)
}

.stp {
  border: none;
  border: var(--outline);
  border-radius: .5rem;
  box-shadow: var(--shadow);
  display: inline-flex;
  font-family: var(--font);
  outline: none;
  padding: .5rem .4rem
}

.stp--disabled {
  pointer-events: none
}

.stp--divider {
  align-items: center;
  display: inline-flex;
  justify-content: center
}

.stp--divider__disabled {
  opacity: var(--disabled-opacity)
}

.stp * {
  outline: none
}

.stp:focus {
  box-shadow: var(--ring-shadow)
}

.stp > p {
  display: inline;
  margin: 0 2px
}
