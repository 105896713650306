.description-slider-root .slick-track {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.description-slider-root .slick-slide {
  width: 100%;
}

.description-slider-root .slick-dots {
  padding-bottom: 10px;

  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 5px;

  list-style: none;
}

.description-slider-root .slick-dots li {
  width: 7px;
  height: 7px;
  border-radius: 50%;

  background-color: #D9D9D9;
}

.description-slider-root .slick-dots .slick-active li {
  background-color: #5D5D5B;
}
